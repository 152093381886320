import Cookies from "js-cookie"
import { virtual, useEffect, useRef, useState } from "haunted"
import { render, html, nothing } from "lit-html"
import { v4 as uuid } from "uuid"

import { ERROR_MESSAGE } from "../constants"
import { ref } from "../directives"
import { addToastMessage } from "../generic/messages"
import Modal from "../generic/modal"
import AzureUpload from "../utils/azure"

const ATTACHMENT = "attachment"
const LINK = "link"
const RECENT_FILE = "select from recent"
const MODAL_MOUNT_ID = "modal-mount"
const PENDING_ATTACHMENT_FORM_PREFIX = "pending-attachment-form-"

const fetchHXContent = (pathname, target, ref, append = true) => {
  const mount = document.createElement("div")
  render(
    html`<div
      hx-get="${pathname}"
      hx-swap="outerHTML"
      hx-target="${target}"
      hx-trigger="load"
    ></div>`,
    mount
  )
  append ? ref.current.appendChild(mount) : ref.current.prepend(mount)
  window.htmx.process(ref.current)
}

const submitForm = ($form) => {
  if ($form.checkValidity()) {
    if ($form.hasAttribute("hx-post")) {
      window.htmx.trigger($form, "submit")
    } else {
      $form.submit()
    }
  } else {
    $form.reportValidity()
  }
}

const submitParentForm = (ref, create) => {
  const $form = ref.current.closest("form")
  if (create) {
    submitForm($form)
  } else {
    fetch($form.dataset.updatePath, {
      method: "PUT",
      credentials: "include",
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        documents_id: [
          ...$form.querySelectorAll(`input[name="documents"]`),
        ].map(($input) => $input.value),
      }),
    })
      .then((res) => {
        if (res.ok) {
          const $attachments = $form.closest(".attachments-container")
          $attachments.dispatchEvent(new CustomEvent("refresh"))
        } else {
          return res.text().then((text) => {
            throw new Error(text)
          })
        }
      })
      .catch((err) => {
        console.error(err)
        addToastMessage(
          "There is an error when saving the attachments. Please try again.",
          "error"
        )
      })
  }
}

const processAttachmentForm = (
  formPrefix,
  submitFormsRef,
  onFinishSubmitAttachments
) => {
  const $submitForms = submitFormsRef.current
  const formIds = new Set($submitForms.dataset.submitForms.split(","))
  formIds.delete(formPrefix)

  if (formIds.size) {
    $submitForms.dataset.submitForms = Array.from(formIds)
  } else {
    onFinishSubmitAttachments()
  }
}

const createModal = (children, onClose) => {
  const modalMount = document.getElementById(MODAL_MOUNT_ID)
  render(
    Modal({
      onClose,
      modalBackgroundClass: "modal-background bg-white bg-opacity-90",
      children,
    }),
    modalMount
  )
}

export const confirmDeleteAttachment = ($el, onAfterDelete = () => {}) => {
  const modalMount = document.getElementById(MODAL_MOUNT_ID)

  const onClose = () => {
    render(html``, modalMount)
  }

  const onConfirm = () => {
    $el.remove()
    onAfterDelete()
    onClose()
  }

  createModal(
    html`
      <div
        class="modal-content mx-auto max-w-[660px] shadow-[0px_0px_4px_0px_rgba(203,211,219,0.55)] bg-white p-8 text-black"
      >
        <h2 class="mb-8 text-xl font-bold">Delete attachment</h2>
        <p class="mb-8">
          This attachment will be permanently deleted. Are you sure you want to
          delete this attachment?
        </p>
        <div class="flex justify-end items-center">
          <button
            type="button"
            @click=${onClose}
            class="border-px border-blue-200 rounded-md bg-blue-100 px-6 py-4 text-blue font-bold"
          >
            Do not delete
          </button>
          <button
            type="button"
            @click=${onConfirm}
            class="ml-5 rounded-md bg-blue px-6 py-4 text-white font-bold"
          >
            Yes, delete
          </button>
        </div>
      </div>
    `,
    onClose
  )
}

const confirmSaveAnyway = (onAfterConfirm = () => {}) => {
  const onClose = () => {
    render(html``, document.getElementById(MODAL_MOUNT_ID))
  }

  const onConfirm = () => {
    onAfterConfirm()
    onClose()
  }

  createModal(
    html`
      <div
        class="modal-content mx-auto max-w-[660px] shadow-[0px_0px_4px_0px_rgba(203,211,219,0.55)] bg-white p-8 text-black"
      >
        <h2 class="mb-8 text-xl font-bold">Upload incomplete</h2>
        <p class="mb-8">
          The file has not yet finished uploading. Saving now will discard the
          unfinished file.
        </p>
        <div class="flex justify-end items-center">
          <button
            type="button"
            @click=${onConfirm}
            class="rounded-md bg-gray-bg px-6 py-4 text-gray-interactive font-bold"
          >
            Save anyway
          </button>
          <button
            type="button"
            @click=${onClose}
            class="ml-5 rounded-md bg-blue px-6 py-4 text-white font-bold"
          >
            Continue upload
          </button>
        </div>
      </div>
    `,
    onClose
  )
}

const onCancel = (ref) => {
  const $nestedDocument = ref.current
  const $addAttachments = $nestedDocument.closest(".add-attachments")
  if ($addAttachments) {
    $addAttachments.innerHTML = ``
  } else {
    const $container = $nestedDocument.closest(".attachments-container")
    $container?.dispatchEvent(new CustomEvent("refresh"))
  }
}

const saveAttachments = ({
  nestedDocumentRef,
  attachmentRowsRef,
  submitFormsRef,
  create,
}) => {
  const $attachmentRows = attachmentRowsRef.current

  const submitAttachments = () => {
    const forms = $attachmentRows.querySelectorAll("form")
    const submitFormIds = Array.from(forms).map(
      ($form) => $form.querySelector(`input[name="prefix"]`).value
    )
    submitFormsRef.current.dataset.submitForms = submitFormIds
    if (forms.length) {
      forms.forEach(($form) => submitForm($form))
    } else {
      submitParentForm(nestedDocumentRef, create)
    }
  }

  const $pendingForms = $attachmentRows.querySelectorAll(
    `[id^=${PENDING_ATTACHMENT_FORM_PREFIX}]`
  )
  if ($pendingForms.length) {
    confirmSaveAnyway(submitAttachments)
  } else {
    submitAttachments()
  }
}

const FileUpload = virtual(
  ({ createEndpoint, attachmentRowsRef, acceptedType = "*" }) => {
    const fileInputRef = useRef(null)

    const onDragFileOver = (e) => {
      e.preventDefault()
    }

    const onDropFile = (e) => {
      e.preventDefault()

      const dt = new DataTransfer()
      if (e.dataTransfer.items) {
        Array.from(e.dataTransfer.items).forEach((item) => {
          if (item.kind === "file") dt.items.add(item.getAsFile())
        })
      } else {
        Array.from(e.dataTransfer.files).forEach((file) => {
          dt.items.add(file)
        })
      }
      fileInputRef.current.files = dt.files
      fileInputRef.current.dispatchEvent(new Event("change"))
    }

    const onFileChange = (e) => {
      const { files } = e.target
      Array.from(files).forEach(onUploadFile)
    }

    const onUploadFile = (file) => {
      const fileName = file.name.split("/").pop()
      const pendingFormId = `${PENDING_ATTACHMENT_FORM_PREFIX}${uuid()}`
      const $pendingForm = document.createElement("div")
      $pendingForm.id = pendingFormId
      render(
        html`<div class="mt-7 rounded-md bg-gray-bg pt-5 pb-4 px-4">
          <div class="mb-3">
            <i class="mr-4 w-6 fas fa-file text-black"></i>
            <span class="text-sm text-gray-interactive">${fileName}</span>
          </div>
          <progress class="progress mb-4" value="0" max="100">
        </div>`,
        $pendingForm
      )
      attachmentRowsRef.current.prepend($pendingForm)

      const onProgress = (p) => {
        $pendingForm.querySelector("progress").value = p
      }
      const onFinish = (value) => {
        fetchHXContent(
          `${createEndpoint}?link=${value}`,
          `#${pendingFormId}`,
          attachmentRowsRef,
          false
        )
      }
      const onError = (message) => {
        $pendingForm.remove()
        console.error(message)
        addToastMessage(ERROR_MESSAGE)
      }

      const upload = new AzureUpload(onProgress, onFinish, onError)
      upload.beginUpload(file, file.name, "")
    }

    const fileInputId = `file-input-${uuid()}`
    return html`
      <div
        class="w-full min-h-100 rounded-md border-px border-dashed border-gray-border flex flex-col justify-center items-center"
        @dragover=${onDragFileOver}
        @drop=${onDropFile}
      >
        <label
          tabindex="0"
          for="${fileInputId}"
          class="block mb-4 text-blue font-bold cursor-pointer"
          >Choose a file</label
        >
        <input
          id="${fileInputId}"
          class="hidden"
          type="file"
          accept="${acceptedType}"
          ?ref=${ref(fileInputRef)}
          @change=${onFileChange}
        />
        <span class="block text-gray-interactive text-sm"
          >or drag & drop file here</span
        >
      </div>
    `
  }
)

const confirmUnsavedChanges = (e, containerId) => {
  const $nestedAttachments = document.querySelector(
    `#${containerId} [data-action="nested-document-input"]`
  )
  if (!$nestedAttachments) return

  const inputsWithValues = Array.from(
    $nestedAttachments.querySelectorAll(
      `input[name$="name"], input[name$="link"], textarea[name$="description"]`
    )
  ).some(($input) => $input.value.trim() !== "")

  if (inputsWithValues) e.preventDefault()
}

const confirmUnsavedReporting = (e) => confirmUnsavedChanges(e, "reporting")
const confirmUnsavedAttachments = (e) => confirmUnsavedChanges(e, "attachments")

const NestedReportingInput = virtual(
  ({ label, createEndpoint, documentEndpoints, attachment }) => {
    const activeSection = attachment ? ATTACHMENT : LINK
    const [enableSaveBtn, setEnableSaveBtn] = useState(false)
    const [hasSubmission, setHasSubmission] = useState(false)
    const nestedDocumentRef = useRef(null)
    const attachmentRowsRef = useRef(null)
    const submitFormsRef = useRef(null)

    const fileAcceptedType = {
      Audio: ".mp3,.wav,.flac,.aac,.ogg,.wma,.m4a,.aiff,.alac,.opus",
      Photo:
        ".jpg,.jpeg,.png,.gif,.bmp,.webp,.tiff,.svg,.ico,.eps,.psd,.ai,.indd,.raw,.heic",
    }

    useEffect(() => {
      window.htmx.process(attachmentRowsRef.current)

      const $reporting = nestedDocumentRef.current.closest("#reporting")
      const $attachmentRows = $reporting.querySelectorAll(`[id^="document-"]`)
      const $attachmentForms = $reporting.querySelectorAll(
        ".attachment-form:not(.add-attachments .attachment-form)"
      )
      const roleHasSubmission =
        $attachmentRows.length > 0 || $attachmentForms.length > 0
      setHasSubmission(roleHasSubmission)
      if (!roleHasSubmission) {
        const $form = nestedDocumentRef.current.closest("form")
        $form.firstElementChild.classList = []
      }

      const handleAfterSwap = (e) => {
        const $nestedDocumentInput = nestedDocumentRef.current
        $nestedDocumentInput
          .querySelectorAll(".htmx-added > form")
          .forEach(($form) => {
            $form
              .querySelector("button[data-delete]")
              ?.addEventListener("click", (e) => {
                e.stopPropagation()
                confirmDeleteAttachment($form.parentNode, updateButtonStates)
              })
            if (submitFormsRef.current.dataset.submitForms !== "-1") {
              const responseUrl = new URL(e.detail.xhr.responseURL)
              const formPrefix = responseUrl.searchParams.get("prefix")
              if (formPrefix) {
                processAttachmentForm(formPrefix, submitFormsRef, () =>
                  submitParentForm(nestedDocumentRef, false)
                )
              }
            }
          })

        updateButtonStates()
      }

      document.addEventListener("htmx:afterSwap", handleAfterSwap)

      return () => {
        document.removeEventListener("htmx:afterSwap", handleAfterSwap)
      }
    }, [])

    useEffect(() => {
      enableSaveBtn
        ? window.addEventListener("beforeunload", confirmUnsavedReporting)
        : window.removeEventListener("beforeunload", confirmUnsavedReporting)
    }, [enableSaveBtn])

    const updateButtonStates = () => {
      setEnableSaveBtn(
        attachmentRowsRef.current.querySelectorAll("form").length > 0
      )
    }

    const onSave = () => {
      saveAttachments({
        nestedDocumentRef,
        attachmentRowsRef,
        submitFormsRef,
        create: false,
      })
    }

    return html`
      <div data-action="nested-document-input" ?ref=${ref(nestedDocumentRef)}>
        <div class="field-wrapper">
          <div class="mb-6 flex justify-between items-center">
            ${hasSubmission
              ? html`<h2 class="text-lg font-bold text-black">
                  Add attachments
                </h2>`
              : html`<h2 class="text-xl font-bold text-black">${label}</h2>`}
            <div class="shrink-0 flex">
              ${attachment
                ? html`${hasSubmission || enableSaveBtn
                    ? html`<button
                        type="button"
                        @click=${() => onCancel(nestedDocumentRef)}
                        class="cancel-btn"
                      >
                        Cancel
                      </button>`
                    : nothing}`
                : html`<button
                    type="button"
                    @click=${() => {
                      nestedDocumentRef.current
                        .querySelectorAll(".attachment-form form")
                        .forEach(($attachmentForm) => {
                          $attachmentForm.reset()
                        })
                    }}
                    class="cancel-btn"
                  >
                    Cancel
                  </button>`}
              <button
                type="button"
                ?disabled=${!enableSaveBtn}
                class="ml-4 save-btn"
                @click="${onSave}"
              >
                Submit
              </button>
            </div>
          </div>
          <div class="${activeSection !== ATTACHMENT ? "hidden" : "block"}">
            ${FileUpload({
              createEndpoint,
              attachmentRowsRef,
              acceptedType: fileAcceptedType[label],
            })}
          </div>
          <div ?ref=${ref(attachmentRowsRef)}>
            <div ?ref=${ref(submitFormsRef)} data-submit-forms="-1"></div>
            ${documentEndpoints.map(
              (docEndpoint) => html`
                <div
                  hx-get="${docEndpoint}"
                  hx-target="this"
                  hx-swap="outerHTML"
                  hx-trigger="load"
                ></div>
              `
            )}
            ${documentEndpoints.length === 0 && activeSection === LINK
              ? html`
                  <div
                    hx-get="${createEndpoint}"
                    hx-swap="outerHTML"
                    hx-target="this"
                    hx-trigger="load"
                  ></div>
                `
              : nothing}
          </div>
        </div>
      </div>
    `
  }
)

const NestedAttachmentsInput = virtual(
  ({ label, createEndpoint, searchEndpoint, documentEndpoints, create }) => {
    const [activeSection, setActiveSection] = useState(ATTACHMENT)
    const [enableSaveBtn, setEnableSaveBtn] = useState(false)
    const nestedDocumentRef = useRef(null)
    const attachmentRowsRef = useRef(null)
    const submitFormsRef = useRef(null)
    const addAnotherBtnRef = useRef(null)
    const searchInputRef = useRef(null)
    const searchResultsRef = useRef(null)

    useEffect(() => {
      window.htmx.process(nestedDocumentRef.current)

      const $nestedDocumentInput = nestedDocumentRef.current
      // If create is true, submit the parent form also save attachments
      if (create) {
        const $parentForm = $nestedDocumentInput.closest("form")
        const $submitBtn = $parentForm.querySelector(
          "header > button[type=submit]"
        )
        $submitBtn.addEventListener("click", (e) => {
          e.preventDefault()
          onSave()
        })
      }

      const handleAfterSwap = (e) => {
        $nestedDocumentInput
          .querySelectorAll(
            ".htmx-added .search-recent-attachment > [id^=document]"
          )
          .forEach(($attachment) => {
            $attachment.classList.add("recent-attachment")

            const $attachmentUrl = $attachment.querySelector("a")
            // When click to attachment row, instead of opening the attachment, add attachment to recent list
            $attachmentUrl.addEventListener("click", (e) => {
              e.preventDefault()
              fetchHXContent(
                $attachment.dataset.editPath,
                "this",
                attachmentRowsRef,
                false
              )
            })
            // But clicking the attachment name will open the attachment URL
            $attachmentUrl
              .querySelector(".attachment-name")
              .addEventListener("click", () => {
                window.open($attachmentUrl.href, "_blank")
              })

            $attachment.querySelector("a")?.addEventListener("click", (e) => {
              e.stopPropagation()
            })
          })

        $nestedDocumentInput
          .querySelectorAll(".htmx-added > form")
          .forEach(($form) => {
            $form
              .querySelector("button[data-delete]")
              ?.addEventListener("click", () => {
                confirmDeleteAttachment($form.parentNode, updateButtonStates)
              })
            if (submitFormsRef.current.dataset.submitForms !== "-1") {
              const responseUrl = new URL(e.detail.xhr.responseURL)
              const formPrefix = responseUrl.searchParams.get("prefix")
              if (formPrefix) {
                processAttachmentForm(formPrefix, submitFormsRef, () => {
                  submitParentForm(nestedDocumentRef, create)
                })
              }
            }
          })

        updateButtonStates()
      }

      document.addEventListener("htmx:afterSwap", handleAfterSwap)

      return () => {
        document.removeEventListener("htmx:afterSwap", handleAfterSwap)
      }
    }, [])

    useEffect(() => {
      enableSaveBtn && !create
        ? window.addEventListener("beforeunload", confirmUnsavedAttachments)
        : window.removeEventListener("beforeunload", confirmUnsavedAttachments)
    }, [enableSaveBtn])

    const updateButtonStates = () => {
      setEnableSaveBtn(
        attachmentRowsRef.current.querySelectorAll("form").length > 0
      )
    }

    const onSave = () => {
      saveAttachments({
        nestedDocumentRef,
        attachmentRowsRef,
        submitFormsRef,
        create,
      })
    }

    const onKeydown = (e) => {
      if (e.code === "Enter") {
        e.preventDefault()

        searchResultsRef.current.innerHTML = ""
        fetchHXContent(
          `${searchEndpoint}?q=${searchInputRef.current.value}`,
          "this",
          searchResultsRef
        )
      }
    }

    const cleanEmptyForms = () => {
      const attachmentRows = attachmentRowsRef.current
      attachmentRows.querySelectorAll("form").forEach(($form) => {
        if ($form.querySelector(`input[name$="link"]`)?.value === "") {
          $form.closest(".attachment-form").remove()
        }
      })
      updateButtonStates()
    }

    const onClickAttachment = () => {
      setActiveSection(ATTACHMENT)
      cleanEmptyForms()
    }

    const onClickLink = () => {
      const attachmentRows = attachmentRowsRef.current
      const forms = attachmentRows.querySelectorAll("form")
      if (forms.length) {
        const $form = forms[forms.length - 1]
        if ($form.querySelector(`input[name$="link"]`)?.value !== "") {
          addAnotherBtnRef.current.click()
        }
      } else {
        addAnotherBtnRef.current.click()
      }
      setActiveSection(LINK)
      updateButtonStates()
    }

    const onClickRecentFile = () => {
      setActiveSection(RECENT_FILE)
      cleanEmptyForms()
    }

    return html`
      <div data-action="nested-document-input" ?ref=${ref(nestedDocumentRef)}>
        <div
          class="field-wrapper ${create
            ? "border-b-px"
            : ""} border-gray-border pb-7"
        >
          <div class="mb-6 flex justify-between items-center">
            ${create
              ? html`<label class="block w-full font-bold">${label}</label>`
              : html`<h2 class="text-lg font-bold text-black">${label}</h2>`}
            ${create
              ? nothing
              : html`
                  <div class="shrink-0 flex">
                    <button
                      type="button"
                      @click=${() => onCancel(nestedDocumentRef)}
                      class="cancel-btn"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      ?disabled=${!enableSaveBtn}
                      class="ml-4 save-btn"
                      @click="${onSave}"
                    >
                      Save
                    </button>
                  </div>
                `}
          </div>
          <div class="md:-mx-3 mb-5 md:flex">
            <button
              type="button"
              role="menuitem"
              @click="${onClickAttachment}"
              class="${activeSection === ATTACHMENT
                ? "active"
                : ""} md:mx-3 mb-2 w-full grow basis-0"
            >
              <i class="mr-3 fa-solid fa-paperclip rotate-45"></i>
              Attachment
            </button>
            <button
              type="button"
              role="menuitem"
              @click="${onClickLink}"
              class="${activeSection === LINK
                ? "active"
                : ""} md:mx-3 mb-2 w-full grow basis-0"
            >
              <i class="mr-3 fa-solid fa-link"></i>
              Link
            </button>
            ${searchEndpoint
              ? html`
                  <button
                    type="button"
                    role="menuitem"
                    @click="${onClickRecentFile}"
                    class="${activeSection === RECENT_FILE
                      ? "active"
                      : ""} md:mx-3 mb-2 w-full grow basis-0"
                  >
                    Select from recent
                    <i class="ml-7 fas fa-caret-down"></i>
                  </button>
                `
              : nothing}
          </div>
          <div class="${activeSection !== ATTACHMENT ? "hidden" : "block"}">
            ${FileUpload({
              createEndpoint,
              attachmentRowsRef,
            })}
          </div>
          ${searchEndpoint
            ? html`
                <div
                  class="${activeSection !== RECENT_FILE
                    ? "hidden"
                    : "block"} border-b-px border-gray-border pb-5"
                >
                  <input
                    placeholder="Search"
                    type="text"
                    ?ref=${ref(searchInputRef)}
                    @keydown=${onKeydown}
                    class="mb-5"
                  />
                  <div ?ref=${ref(searchResultsRef)}>
                    <div
                      hx-get="${searchEndpoint}"
                      hx-target="this"
                      hx-swap="outerHTML"
                      hx-trigger="load"
                      class="mt-2 mb-10 md:mb-14 loader-container loading"
                    ></div>
                  </div>
                </div>
              `
            : nothing}

          <div ?ref=${ref(attachmentRowsRef)}>
            <div ?ref=${ref(submitFormsRef)} data-submit-forms="-1"></div>
            ${documentEndpoints.map(
              (docEndpoint) => html`
                <div
                  hx-get="${docEndpoint}"
                  hx-target="this"
                  hx-swap="outerHTML"
                  hx-trigger="load"
                ></div>
              `
            )}
            <button
              ?ref=${ref(addAnotherBtnRef)}
              type="button"
              class="${activeSection === LINK
                ? "block"
                : "hidden"} mt-4 text-blue font-bold"
              hx-get="${createEndpoint}?category=URL"
              hx-swap="beforebegin"
              hx-target="this"
              hx-trigger="click"
            >
              Add another
            </button>
          </div>
        </div>
      </div>
    `
  }
)

export default function renderNestedDocumentFormInput($el) {
  const $documentFieldWrapper = $el.closest(".field-wrapper")
  if ($documentFieldWrapper === null) return
  const mount = document.createElement("div")
  const renderComponent = $el.hasAttribute("data-primary")
    ? NestedReportingInput
    : NestedAttachmentsInput
  render(
    renderComponent({
      label: $documentFieldWrapper.querySelector("label")?.textContent.trim(),
      createEndpoint: $el.dataset.createEndpoint,
      searchEndpoint: $el.dataset.searchEndpoint,
      documentEndpoints: [...$el.querySelectorAll("[data-doc-endpoint]")].map(
        ($doc) => $doc.dataset.docEndpoint
      ),
      attachment: $el.hasAttribute("data-attachment"),
      create: $el.hasAttribute("data-create"),
    }),
    mount
  )
  $documentFieldWrapper.replaceWith(...mount.childNodes)
}
